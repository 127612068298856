.work-main {
  font-size: 10px;
  color: #969696;
  margin-top: 6rem;
  height: 100%;
  margin-inline: auto;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  /* background-color: red; */
}

.header_work {
  color: var(--name-text-color);
  font-size: 1.8rem;
  font-weight: bolder;
  margin-inline: 8rem;
  font-family: monospace;
}
.header_work::before {
  content: "03. ";
  color: var(--blue-main);
}
@media screen and (max-width: 768px) {
  .header_work {
    margin-inline: 0;
  }
  .work-main {
    text-align: center;
  }
}

/* ===================Other Works Css ====================== */

.container-otherworks {
  width: 100%;
  height: 100%;
}
.other_things {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 5rem;
}
.sub_heading {
  font-size: 1.5rem;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  /* margin-right: 20%; */
}

.folders {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.section-two {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}
.last_section {
  margin-bottom: 1rem;
}
.half_section {
  margin-bottom: 1rem;
}

.showbtn {
  max-width: 100px;
  max-height: 40px;
  min-width: 100px;
  min-height: 40px;
  align-self: center;
  color: var(--secondary-color);
  background-color: #2b2c2c;
  border: none;
  border-radius: 5px;
  transition: all 0.5s ease;
}
@media screen and (max-width: 768px) {
  .container {
    height: 100%;
  }
  .folders {
    flex-direction: column !important;
    align-self: center;
  }
  .section-two {
    flex-direction: column !important;
    align-self: center;
  }
}
@media screen and(max-width:990px) {
  .folders {
    gap: 2.5rem;
  }
  .section-two {
    gap: 2.5rem;
  }
}

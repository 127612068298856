.footer {
  width: 100%;
  height: 50px;
  /* background-color: brown; */
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_text {
  font-size: 12px;
  color: var(--secondary-color);
}

.footer-git span {
  margin-left: 5px;
}
.footer-git {
  margin-top: 10px;
  font-size: 15px;
  color: var(--icon-color);
}
.footer-git:hover {
  color: var(--orange-color);
  transition: all 0.3s ease-in-out;
}

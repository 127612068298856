:root {
  --primary-color: #101010;
  --secondary-color: #b2a2a2;
  --navtext-main: #dddddd;
  --blue-main: #07bbff;
}
@font-face {
  font-family: Cascadia Code;
  src: url(../../../public/fonts/CascadiaCode.ttf);
}

.nav-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px auto;
  transition: 0.3s ease-in;
  /* overflow: hidden; */
  background-color: transparent;
  font-family: "Cascadia Code";
  user-select: none;
}
.navbarblur {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px auto;

  background-color: rgb(16, 16, 16, 0.2);
  backdrop-filter: blur(12px);
  transition: 1s ease;
  font-family: "Cascadia Code";
}

.nav-menu a {
  color: var(--navtext-main);
  text-decoration: none;
}

.nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75%;
}
.logo {
  font-size: 30px;
  color: white;
  margin: 15px;
  margin-left: 40px;
  width: 70px;
  height: 70px;
  transform-origin: center;
  transform-box: fill-box;
}

.nav-menu a:hover {
  color: var(--blue-main);
  transition: ease-in-out 0.3s;
}
.nav-item .active {
  color: var(--blue-main);
  transition: ease-in-out 0.3s;
}

.hamburger {
  display: none;
}
.home-nav,
.about-nav,
.work-nav,
.contact-nav {
  text-decoration: none !important;
  list-style-type: none;
  font-size: 15px;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-family: "Cascadia Code";
}
.home-nav::before {
  content: "01. ";
  color: var(--blue-main);
}
.about-nav::before {
  content: "02. ";
  color: var(--blue-main);
}
.work-nav::before {
  content: "03. ";
  color: var(--blue-main);
}
.contact-nav::before {
  content: "04. ";
  color: var(--blue-main);
}
@media screen and (max-width: 968px) {
  .navbar {
    max-width: 100%;
    width: 65px auto;
    max-width: 100%;
  }
  .navbarblur {
    max-width: 100%;
    max-width: 100%;
  }

  .hamburger {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75%;
    background: none;
    color: #fff;
    margin: 0 30px;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: #050505;
    width: 100%;
    height: 18rem;
    z-index: 99;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .logo {
    font-size: 30px;
    color: white;
    margin: 15px;
    margin-left: 40px;
    width: 58px;
    height: 58px;
  }
  .nav_outlinedbtn {
    margin-left: 0rem;
  }
}

.nav_outlinedbtn {
  background-color: transparent;
  border: 1px solid var(--blue-main);
  box-shadow: 1px 1px 1px 1px #000;
  color: var(--blue-main) !important;
  font-size: 15px;
  padding: 8px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-family: "Calibre";
  text-decoration: none !important;
  list-style-type: none;
  border-radius: 5px;
  margin-right: 1.2rem;
}
.nav_outlinedbtn a {
  color: var(--blue-main) !important;
  text-align: center;
  font-size: 18px;
}
.nav_outlinedbtn:hover {
  background-color: #07bdff15;
  color: white;
  transition: ease-in-out 0.3s;
}

@keyframes navreveal {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* .textdiv {
  animation: reveal 1.5s ease;
} */

.home {
  display: flex;
  margin-top: 15rem;
  padding: 0 7rem;
  max-width: 100%;
  max-height: 100%;
  height: 90vh;
}

.textdiv {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  color: var(--secondary-color);
  line-height: 2.5rem;
}

.basic-text2 {
  font-family: "Calibri" sans-serif;
  font-weight: bold;
  letter-spacing: 5px;
  color: var(--green-color);
}

.basic-text3 {
  font-size: 1rem;
  font-family: "Calibre";
  color: #797575;
}

.big-text {
  font-size: 2rem;
  font-family: "Calibri" sans-serif;
  font-weight: bolder;
  background: linear-gradient(
    -45deg,
    #ee7752,
    #e73c7e,
    #23a6d5,
    #23d5ab,
    #fff,
    #fff,
    #fff,
    #fff
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  animation-delay: 10s;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  user-select: none;
}
.links-left {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 4rem;
  left: 0;
  margin-left: 1.5rem;
  color: var(--name-text-color);
  font-size: 1.2rem;
  z-index: 50;
  /* animation: reveal1 1.5s ease-in-out; */
}
.links-left a {
  margin: 5px;
  color: var(--name-text-color);
}
.fa-hackerrank,
.fa-youtube {
  font-size: 1.1rem !important;
}
.links-left i:hover {
  transform: translateX(-4px) !important;
  font-size: 1.6rem !important;
  color: #9684a5;
  transition: ease-in-out 0.2s;
}

.link-left-after {
  content: " ";
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 1.5px;
  height: 3.5rem;
  margin-left: 2.3rem;
  background-color: var(--violet-color);
  /* animation: reveal1 1.5s ease-in-out; */
}
.email-right {
  width: 7.5rem;
  height: 1.5px;
  margin-top: 10px;
  margin-left: 10px;
  background-color: var(--blue-main);
  /* animation: reveal1 1.5s ease-in-out; */
}
.email {
  color: var(--secondary-color);
}
.email:hover {
  color: var(--blue-main);
  cursor: pointer;
  transition: ease 0.2s;
}
.emaildiv {
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  z-index: 50;
  /* animation: reveal1 1.5s ease-in-out; */
}
.side {
  user-select: none;
}

@media screen and (max-width: 768px) {
  .side {
    display: none;
  }
  .home {
    text-align: center;
    margin-top: 10rem;
  }
  .big-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--name-text-color);
  }
  .basic-text {
    font-size: 20px;
    color: var(--secondary-color);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}
